@import "https://fonts.googleapis.com/css?family=Tangerine";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #FFFFFF;
}

h1 {
  font-family: 'Tangerine', serif;
  font-size: 60px;
  text-shadow: 4px 4px 4px #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
}

li {
  align-items: center;
  justify-content: center;
}

audio {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.container {
  height: 100%;
  padding: 20px;

  background: #83a4d4;
  background: linear-gradient(to left, #b6fbff, #83a4d4);

  color: #171212;
}

.headline-primary {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 2px;
}

.button {
  background: transparent;
  border: 0px solid #171212;
  padding: 5px;
  cursor: pointer;

  transition: all 0.1s ease-in;
}

.button:hover {
  background: #171212;
  color: #FFFFFF;
}

.button:hover > svg > g {
  stroke: #FFFFFF;
}


.button_small {
  padding: 5px;
}

.button_large {
  padding: 0;
}

.button_search:hover {
  background: transparent;
}

.button_remove:hover {
  background: #171212;
  fill: #FFFFFF;
}