.label {
    border-top: 1px solid #171212;
    border-left: 1px solid #171212;
    padding-left: 5px;
    font-size: 24px;
  }
  
  .input {
    border: none;
    border-bottom: 1px solid #171212;
    background-color: transparent;
    font-size: 24px;
  }
  
  .input:focus {
    outline: none;
  }
  