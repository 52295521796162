.main {
    padding: 1rem 0;
}

.nav {
    display: flex;
    border-bottom: solid 1px;
    padding-bottom: 1rem;
}

